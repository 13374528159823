"use client";
import React, { useEffect, useRef, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { BsApple } from "react-icons/bs";
import { FaTwitter } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import { useRouter, useSearchParams } from "next/navigation";
import Link from "next/link";
import { Spinner } from "@material-tailwind/react";

import { Get, Post } from "@/services/fetchServices";
import { useQuizContext } from "@/context/context";
import { useI18n } from "@/locales/client";

import "react-toastify/dist/ReactToastify.css";

const Auth = ({ pageUrl = "register", courseId = "", fromEnroll = false }) => {
  const { allCourses } = useQuizContext() || { allCourses: [] };
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInputClicked, setIsInputClicked] = useState(false);
  const [userData, setUserData] = useState({
    courseName: "",
    email: "",
    fullName: "",
    phone: "",
    courseId,
  });
  const modalRef = useRef(null);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const t = useI18n();
  const [isError, setIsError] = useState({
    email: false,
    password: false,
  });

  const [isErrorLogin, setIsErrorLogin] = useState(false);

  useEffect(() => {
    localStorage.getItem("userData") && setIsLoggedIn(true);
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      router.push("/");
    }
  }, [isLoggedIn]);
  const requestPostLogin = async (data) => {
    await Post("users/auth/sign-in", data, {
      withCredentials: true,
    })
      .then(() => {
        toast.success("You have successfully signed in", {
          position: "bottom-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((error) => {
        setIsErrorLogin(true);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    await Get("users/v1/user-profile", {
      withCredentials: true,
    })
      .then((response) => {
        localStorage.setItem("userData", JSON.stringify(response));
        router.push("/");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userData.courseId || userData.courseId.length === 0) {
      toast.warning("Please fill the course", {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (!userData.fullName || userData.fullName.length === 0) {
      toast.warning("Please fill the full name", {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (!userData.phone || userData.phone.length === 0) {
      toast.warning("Please fill the phone", {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (!userData.email || userData.email.length === 0) {
      toast.warning("Please fill the email", {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      fromEnroll && router.back();
      const res = await Post("applications-ms/v1/applications", userData);
      toast.success("Please check your email", {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setUserData({ email: "", phone: "", fullName: "", courseId: "" });
    }
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    requestPostLogin({ username: data.email, password: data.password });
  };

  const handleChangesLogin = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeInput = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    let formattedInput = inputValue ? "(" : "";
    for (let i = 0; i < inputValue.length; i++) {
      if (i === 3) {
        formattedInput += ") ";
      } else if (i === 6 || i === 8) {
        formattedInput += " ";
      }
      formattedInput += inputValue[i];
    }
    setUserData((prev) => ({ ...prev, phone: formattedInput }));
  };

  const handleClick = (course) => {
    setUserData((prev) => ({
      ...prev,
      courseId: course?.id,
      courseName: course?.name,
    }));
    setIsInputClicked(false);
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsInputClicked(false);
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.body.addEventListener("click", handleOverlayClick);
    }
  }, []);
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105)
    ) {
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };
  if (isLoggedIn) {
    return null;
  }

  return (
    <div className="w-full sm:w-[80%] md:w-[60%] lg:w-auto d-flex items-center">
      <div className="sm:px-8 px-3">
        <div className="mx-auto w-full">
          <div className="mb-5">
            <h1 className="text-[26px] font-bold">
              {pageUrl === "login" ? t("li") : t("gs")}
            </h1>
          </div>
          <form
            onSubmit={pageUrl === "login" ? handleSubmitLogin : handleSubmit}
          >
            <div className="relative">
              {pageUrl === "register" && (
                <>
                  {!fromEnroll && (
                    <div className="mb-5 relative">
                      <input
                        className="py-3 px-4 text-base font-normal rounded-md w-full inputStyle"
                        placeholder={t("sc")}
                        value={
                          allCourses?.find(
                            (course) => course?.id === userData?.courseId
                          )?.name || ""
                        }
                        type="text"
                        readOnly
                        onClick={() => setIsInputClicked(!isInputClicked)}
                      />
                      <div className="absolute right-1 top-1/2 -translate-y-1/2 flex flex-col gap-1 pr-2">
                        <button type="button" className="w-[5px] h-[5px]">
                          &#9650;
                        </button>
                        <button type="button">&#9660;</button>
                      </div>
                    </div>
                  )}
                  {isInputClicked && (
                    <div className="modalOverlay">
                      <div className="modalContent" ref={modalRef}>
                        {allCourses?.map((course) => (
                          <div
                            onClick={() => handleClick(course)}
                            key={course.id}
                            className="cursor-pointer hover:bg-gray-200 py-2 px-4 text-base"
                          >
                            <h2>{course.name}</h2>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="mb-5">
                    <input
                      className="py-3 px-4 text-base font-normal rounded-md w-full inputStyle"
                      type="text"
                      name="fullName"
                      value={userData.fullName}
                      placeholder={t("yn")}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-5">
                    <input
                      className="py-3 px-4 text-base font-normal rounded-md w-full inputStyle"
                      type="tel"
                      inputMode="numeric"
                      maxLength={15}
                      value={userData.phone}
                      onChange={handleChangeInput}
                      onKeyDown={handleKeyPress}
                      pattern="^\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}$"
                      title={t("numberTitle")}
                      placeholder="(055) 000 00 00"
                    />
                  </div>
                  <div className="mb-5">
                    <input
                      className="py-3 px-4 text-base font-normal rounded-md w-full inputStyle"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={userData.email}
                      placeholder={t("ye")}
                    />
                  </div>
                </>
              )}
            </div>
            {pageUrl === "login" && (
              <>
                <div>
                  <div className="mb-1">
                    <label className="font-normal text-base" htmlFor="email">
                      {pageUrl === "login" && t("eou")}
                    </label>
                  </div>
                  <div className="mb-1 relative">
                    <input
                      id="email"
                      name="email"
                      className="py-3 pr-9 pl-2 text-base font-normal rounded-sm w-full inputStyle"
                      value={data.email}
                      onChange={pageUrl === "login" && handleChangesLogin}
                    />
                  </div>
                </div>
              </>
            )}

            {pageUrl === "login" && (
              <>
                <div className="mt-4 mb-8">
                  <div className="mb-1 flex items-center justify-between">
                    <label className="font-normal text-base" htmlFor="password">
                      {t("password")}
                    </label>
                  </div>
                  <div className="mb-1 relative">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={data.password}
                      className="py-3 pr-9 pl-2 text-base font-normal rounded-sm w-full inputStyle"
                      onChange={pageUrl === "login" && handleChangesLogin}
                    />
                  </div>
                  <div>
                    {pageUrl !== "login" &&
                      error.password !== "" &&
                      isError.password && (
                        <small className="text-sm text-red-900">
                          {error.password}
                        </small>
                      )}
                  </div>
                </div>
              </>
            )}

            {pageUrl === "login" ? (
              <div className="flex flex-col">
                {isErrorLogin ? (
                  <small className="text-base text-red-900 mb-5">
                    {t("iep")}
                  </small>
                ) : (
                  ""
                )}
                <Link
                  aria-label="Home"
                  rel="canonical"
                  href="/"
                  className="font-bold underline hover:no-underline mb-6 text-base text-linkColor"
                >
                  {t("ifmp")}
                </Link>
              </div>
            ) : (
              ""
            )}

            <div className="mb-5">
              {loading ? (
                <div
                  className={`submitButton bg-blue-gray-300 ${
                    pageUrl === "login" ? "w-24" : "block"
                  } flex items-center justify-center`}
                >
                  <Spinner color="blue" />
                </div>
              ) : (
                <button
                  type="submit"
                  className={`submitButton bg-[#3a10e5] hover:bg-[#5533ff] ${
                    pageUrl === "login" ? "w-24" : "block"
                  }`}
                >
                  {pageUrl === "login" ? t("logIn") : t("send")}
                </button>
              )}
            </div>
          </form>

          {pageUrl !== "login" && (
            <div className="pb-4">
              <small className="font-normal text-sm text-gray-900">
                {t("termContent")}
                <Link
                  aria-label="Terms of Service"
                  href="https://ingressacademy.gitbook.io/terms-of-service/"
                  target="_blank"
                  className="mx-1 underline text-linkColor hover:no-underline"
                >
                  {t("term")}
                </Link>
                &
                <Link
                  href="https://ingressacademy.gitbook.io/privacy-policy/"
                  target="_blank"
                  className="mx-1 underline text-linkColor hover:no-underline"
                >
                  {t("privacy")}
                </Link>
              </small>
            </div>
          )}
          {pageUrl === "login" && (
            <div className="mb-6">
              <h2 className="font-bold text-base mb-4">{t("olu")}</h2>
              <ul
                className={`${
                  pageUrl === "login" ? "grid-cols-12" : "grid-cols-10"
                } grid gap-x-2`}
              >
                <li className="col-span-2">
                  <form>
                    <button
                      type="button"
                      className="cursor-pointer w-full border border-black bg-white rounded p-1 h-12 flex items-center justify-center hover:bg-blue-gray-50"
                    >
                      <div>
                        <FcGoogle className="w-6 h-6" />
                      </div>
                    </button>
                  </form>
                </li>
                {/*  */}
                <li className="col-span-2">
                  <form>
                    <button
                      type="button"
                      className="cursor-pointer w-full border border-black bg-white rounded p-1 h-12 flex items-center justify-center hover:bg-blue-gray-50"
                    >
                      <div>
                        <FaFacebook className="bg-transparent w-6 h-6 text-blue-400" />
                      </div>
                    </button>
                  </form>
                </li>

                {/*  */}
                <li className="col-span-2">
                  <form>
                    <button
                      type="button"
                      className="cursor-pointer w-full border border-black bg-white rounded p-1 h-12 flex items-center justify-center hover:bg-blue-gray-50"
                    >
                      <div>
                        <BsLinkedin className="bg-transparent w-6 h-6 text-blue-700" />
                      </div>
                    </button>
                  </form>
                </li>

                {/*  */}
                <li className="col-span-2">
                  <form>
                    <button
                      type="button"
                      className="cursor-pointer w-full border border-black bg-white rounded p-1 h-12 flex items-center justify-center hover:bg-blue-gray-50"
                    >
                      <div>
                        <FaGithub className="w-6 h-6" />
                      </div>
                    </button>
                  </form>
                </li>

                {/*  */}
                <li className="col-span-2">
                  <form>
                    <button
                      type="button"
                      className="cursor-pointer w-full border border-black bg-white rounded p-1 h-12 flex items-center justify-center hover:bg-blue-gray-50"
                    >
                      <div>
                        <BsApple className="w-6 h-6" />
                      </div>
                    </button>
                  </form>
                </li>
                {pageUrl === "login" ? (
                  <li className="col-span-2">
                    <form>
                      <button
                        type="button"
                        className="cursor-pointer w-full border border-black bg-white rounded p-1 h-12 flex items-center justify-center hover:bg-blue-gray-50"
                      >
                        <div>
                          <FaTwitter className="w-6 h-6 text-light-blue-600" />
                        </div>
                      </button>
                    </form>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          )}
          {/* <div className="flex font-normal text-base">
            <h2>{pageUrl === "login" ? t("nmt") : t("aha")}</h2>
            {pageUrl === "login" ? (
              <Link
                href="/register"
                className="text-[blue] underline hover:no-underline ml-1 font-bold text"
              >
                {t("register")}
              </Link>
            ) : (
              <Link
                href="/login"
                className="text-[blue] underline hover:no-underline ml-1 font-bold text"
              >
                {t("login")}
              </Link>
            )}
          </div> */}
        </div>
      </div>
      <ToastContainer bodyClassName={() => "flex text-base items-center"} />
    </div>
  );
};

export default Auth;
